

























































import { InputSetups } from "@/mixins/input-setups"
import { FormData } from '@/includes/logic/Forms/types'

import { UseFields } from "piramis-base-components/src/components/Pi"

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { errorNotification } from '@/includes/NotificationService'

@Component
export default class ListItemFormCard extends Mixins(UseFields, InputSetups) {
  @Prop({ 'required': true }) readonly form!: FormData

  @Prop() readonly deleteFormFn!: () => Promise<any>

  @Prop() readonly setupFormFn!: () => Promise<any>

  @Prop() readonly watchFormFn!: () => Promise<any>

  formCardLoading = false

  deleteForm(): void {
    this.formCardLoading = true

    this.$confirm({
      title: this.$t("form_confirm_remove").toString(),
      content: this.$t("form_confirm_remove_text").toString(),
      okText: this.$t("pi_accept").toString(),
      cancelText: this.$t("pi_reject").toString(),
      onOk: async () => {
        try {
          await this.deleteFormFn()
        } catch (error) {
          errorNotification(error)
        } finally {
          this.formCardLoading = false
        }
      },
      onCancel: () => {
        this.formCardLoading = false
      }
    })
  }
}
